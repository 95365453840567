<template>
    <section class="mainBox">
        <NoticeBar
            class="global_notice"
            left-icon="volume-o"
            v-if="noticeBarConfigs && noticeBarConfigs.type === 2 && noticeBarConfigs.content && !closeFlag"
        >
            {{noticeBarConfigs.content}}
            <div class="right-icon-box" slot="right-icon" @click="closeNoticeBar">
                <i-icon name="icon-guanbi" color="#f87700" size="12" ></i-icon>
            </div>
        </NoticeBar>
        <div class="router-view-wrap">
            <keep-alive :include="$store.state.catchPages">
                <router-view :navigation='navList' />
            </keep-alive>
        </div>
        <i-tabbar v-show="showTabBar" v-model="navOn" active-color="var(--themeColor)" inactive-color="#13161B">
            <i-tabbar-item v-for="(item, index) in navList" :key="index" @click.native="navigateTo(item,index)" :badge="item.count === 0 ? '' : item.count">
                <span>{{ item.txt }}</span>
                <template #icon="props">
                    <i-icon :size="22" :name="props.active ? item.iconOn : item.icon" :color="props.active ? 'var(--themeColor)' : ''"></i-icon>
                </template>
            </i-tabbar-item>
        </i-tabbar>
    </section>
</template>

<script>
import PolicyTPM from "@/utils/policy-tpm.js";
import * as tinyCookie from "tiny-cookie";
import {SmartStorage} from "smart-core-util";
import {NoticeBar} from 'vant'
import TPMService from "@/components/iNotice/service";
export default {
    name: "layout",
    data() {
        return {
            navOn: 0,
            navList: [],
            showTabBar: true,
            clientInnerHeight: '',
            closeFlag: false,
            noticeBarConfigs: {}
        };
    },
    components: {
        NoticeBar
    },
    created() {
        this.$root.$eventHub.$on("updateNotReadCount", (count) => {
            this.setNotReadCount(count);
        });
        this.getNavList();
    },
    mounted() {
        this.clientInnerHeight = window.innerHeight
        window.addEventListener('resize',this.handleInnerHeight);
        TPMService.get(2).then(res => {
            if(res.success) {
                this.noticeBarConfigs = res.content;
            }
        })
    },
    destroyed() {
        window.removeEventListener('resize',this.handleInnerHeight)
        this.$root.$eventHub.$off("updateNotReadCount");
    },
    methods: {
        closeNoticeBar(){
            this.closeFlag = true;
        },
        // 给子路由eventList调用的方法，用于控制辉瑞拼团中的tabbar隐藏
        hiddenTabBar(flag){
            this.showTabBar = flag;
        },
        navigateTo(item,index){
            if(item.type === 'openWebApp'){
            this.openWebApp(item);
          }else {
              if (item.homeQueryExpr) {
                  this.$smartStorage.set("homeQueryListExpr", item.homeQueryExpr)
              }
              SmartStorage.set('navOn', index);
              this.$router.replace(item.path);
          }
        },
        // 打开内部站点
        openWebApp(item) {
          let appConf = {
            webAppAlias: item.urlKey,
            path: item.path,
            params: `${item.params}&userId=${tinyCookie.get('userId')}&rnd=${new Date().getTime()}`,
          };
          this.$publicFun.openWebAppCate({}, appConf, item.noSaveCacheData);
        },
        handleInnerHeight() {
            let currentHeight = window.innerHeight
            this.showTabBar = this.clientInnerHeight - currentHeight > 50 ? false : true
        },
        onChange(item) {
            this.$router.push({
                name: item.path,
                params: {
                    init: true,
                },
            });
        },
        setNotReadCount(count) {
            this.navList && this.navList.map((item,index) => {
                if(index === 1) {
                    item.count = count
                }
                return item
            })
        },
        // 获取导航菜单
        getNavList() {
            PolicyTPM.proxy
                .call(this, {
                    collection: "cfg_ievent_nav",
                    method: "GetSettings",
                    conditions: {
                        db: "smartx-tpm",
                        filter: {
                            tenantCode: this.$smartStorage.get("tenant") || tinyCookie.get("tenant"),
                            tenantId: this.$smartStorage.get("tenant_id") || tinyCookie.get("tenant_id"),
                        },
                    },
                })
                .then((response) => {
                    if (response && response.success) {
                        console.log("====导航栏配置", response?.content?.options);
                        this.navList = response?.content?.options || [];
                        // this.navOn = this.$route.meta.index;
                        this.navOn = SmartStorage.get('navOn'); // 直接用数组的顺序
                        this.getMessageList();
                    }
                });
        },
        // 获取消息数量
        getMessageList() {
            let params = {
                channelId: "H5",
                noCount: false,
                pageIndex: 1,
                pageSize: 10,
            };
            this.$service.Paging(params).then((res) => {
                if (res.success) {
                    this.setMessageCount(res);
                }
            });
        },
        // 设置未读消息数量
        setMessageCount(response) {
            if (this.navList.length > 0) {
                this.setNotReadCount(response.content.notReadCount);
            }
        },
    },
};
</script>

<style lang="less" scoped="scoped">
.mainBox {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    .router-view-wrap {
        //position: fixed;
        //top: 0;
        //left: 0;
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 0.59rem);
        flex: 1 0 auto;
        -webkit-overflow-scrolling: touch;
    }
    /deep/ .van-tabbar--fixed {
        //position: fixed !important;
        font-size: 0.1rem;
        height: 0.59rem;
        padding: 0.05rem 0;
        box-sizing: border-box;
        z-index: unset;
    }
    /deep/ .van-tabbar--fixed::after {
        content: none;
    }
    .global_notice{
        position: absolute;
        top: 0px;
        width: calc(100% - 32px);
        z-index: 20;
        height: 50px;
        .right-icon-box{
            padding-left: 12px;
        }
    }
}
</style>
